.standard {

	label {
		display: block;
		margin: 0 0 5px;
		font-weight: bold; }

	input {
		display: block;

		&[type="text"], &[type="date"] {
			margin: 0 0 30px;
			padding: 15px;
			width: calc(100% - 32px);
			border: solid 1px #333; }

		&[type="file"] {
			padding: 15px;
			width: calc(100% - 32px);
			border: dashed 1px #333; }

		&[type="submit"] {
			margin: 40px 0 0;
			padding: 18px 20px;
			min-width: 300px;
			background: #222;
			border: none;
			color: white;
			font-size: 16px;
			text-align: left;
			transition: 0.5s;
			cursor: pointer;

			&:hover {
				background: #333; } } }

	textarea {
		margin: 0 0 30px;
		padding: 15px;
		width: calc(100% - 32px);
		border: solid 1px #333;
		resize: vertical; }

	.set {
		display: flex;

		.field {
			flex: 1;

			&:first-child {
				margin-right: 30px; }

			legend {
				margin: 10px 0 10px;
				padding: 0;
				color: #676767;
				font-size: 0.9em; } } }

	.radioSet {
		margin: 0 0 30px;

		input {
			display: inline-block;
			margin-right: 7px; }

		label {
			display: inline-block;
			margin-right: 15px; } }


	.clickSelector {
		margin: 0 0 30px;

		.option {
			display: inline-block;
			margin: 0 5px 0 0;
			padding: 0 20px;
			background: #888;
			color: white;
			line-height: 45px;
			transition: 0.3s;
			cursor: pointer;

			&.selected {
				background: #333; } } } }
