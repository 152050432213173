.categories {
	margin: 0 0 30px;

	.option {
		display: inline-block;
		margin: 0 5px 0 0;
		padding: 0 20px;
		background: #888;
		color: white;
		line-height: 45px;
		transition: 0.3s;
		cursor: pointer;

		&.selected {
			background: #333; } } }
