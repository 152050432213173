.progressOverlay {
	position: fixed;
	z-index: 5000;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	opacity: 0;
	transition-duration: 0.3s;
	pointer-events: none;

	&.active {
		opacity: 1;
		pointer-events: all; }

	.progressShade {
		position: absolute;
		width: 100%;
		height: 100%;
		background: rgba(0,0,0,0.7); }

	.progressWindow {
		display: flex;
		flex-direction: column;
		position: absolute;
		z-index: 1;
		top: calc(50% - 50px);
		left: calc(50% - 200px);
		padding: 30px;
		width: 340px;
		height: 140px;
		background: white;
		border-radius: 5px;
		box-shadow: 0 5px 10px rgba(0, 0, 0, 0.5);

		.progressText {
			display: flex;
			flex: 1;
			align-items: center;
			justify-content: center;
			margin-bottom: 30px;
			color: black;
			font-size: 20px; }

		.progressBar {
			height: 10px;
			//width: calc(100% - 60px)
			background: #888;
			border-radius: 5px;
			overflow: hidden;

			.progress {
				height: 100%;
				width: 0;
				background: linear-gradient(to right, #1b71b4, #3fb33f);
				opacity: 1;

				&.loading {
					width: 90%;
					transition: 30s; }

				&.completed {
					width: 100%;
					transition: 1s; }

				&.fade {
					opacity: 0;
					transition: 0.3s; } } } } }
