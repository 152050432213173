.panel {
	margin: 200px auto;
	width: 600px;

	.tabs {
		display: flex;

		span {
			flex: 1;
			padding: 10px 20px;
			background: #eee;
			text-align: center;
			cursor: pointer;

			&:last-child {
				margin-left: 1px; }

			&.active {
				background: #333;
				color: white; } } }

	form {
		padding: 30px;
		background: #fafafa;

		&.hidden {
			display: none; }

		input {
			display: block;
			margin: 0 0 8px;
			padding: 10px;
			width: calc(100% - 22px);
			border: solid 1px #ccc;

			&[type="submit"] {
				margin: 25px 0 0;
				width: 100%; } } } }
