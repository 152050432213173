.bar {
	position: relative;
	margin: 0 0 20px;
	padding: 0 0 15px;
	border-bottom: solid 1px #888;

	h1 {
		display: inline-block;
		margin: 0 15px 0 0;
		padding: 0;
		line-height: 1em;
		font-size: 2.5em; }

	.back {
		display: inline-block;
		color: #555; }

	.actions {
		position: absolute;
		bottom: 15px;
		right: 0;
		text-align: right;

		a {
			display: inline-block;
			padding: 8px 10px;
			background: #eee;
			border: solid 1px #aaa;
			color: black;
			line-height: 1em; } } }
