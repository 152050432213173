.panel {
	display: flex;
	align-items: center;
	margin: 0 0 20px;
	padding: 30px;
	border: solid 1px #ccc;

	span {
		flex: 1;
		margin-right: 20px;
		vertical-align: middle; }

	.spacer {
		flex: 1; }

	.actions {
		float: right;
		vertical-align: middle;


		a, button {
			display: block;
			float: left;
			margin-left: 10px;
			padding: 12px 15px;
			background: #333;
			border: none;
			color: white;
			font-size: 11px;
			line-height: 1em;
			vertical-align: middle;
			-webkit-appearance: none; } } }

