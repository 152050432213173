.toast {
	margin: 0 0 15px;
	max-height: 200px;
	padding: 20px;
	background: #32bb6b;
	color: white;

	&.error {
		background: #ad3030; }

	&:global(.enter) {
		margin-bottom: 0;
		padding: 0 20px;
		max-height: 0;
		opacity: 0; }

	&:global(.enter-active) {
		margin-bottom: 15px;
		padding: 20px;
		max-height: 200px;
		opacity: 1;
		transition: 0.5s cubic-bezier(0.9, 0, 0.1, 1), opacity 0.5s 0.3s; }

	&:global(.exit) {
		margin-bottom: 15px;
		padding: 20px;
		max-height: 200px;
		opacity: 1; }

	&:global(.exit-active) {
		margin-bottom: 0;
		padding: 0 20px;
		max-height: 0;
		opacity: 0;
		transition: 0.5s cubic-bezier(0.9, 0, 0.1, 1) 0.5s, opacity 0.5s; } }
