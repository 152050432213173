@mixin panel {
	display: flex;
	margin: 0 0 20px;
	padding: 20px;
	border: solid 1px #ccc;

	.cover {
		margin-right: 20px;
		width: 250px;
		height: 200px;
		background: #eee;

		img {
			width: 100%;
			height: 100%;
			object-fit: cover; } }

	.column {
		flex: 1;
		margin-right: 20px; }

	.actions {
		width: 150px;

		a, button {
			display: block;
			margin: 0 0 5px;
			padding: 10px 10px;
			width: calc(100% - 20px);
			background: #888888;
			border: none;
			color: #fff;
			text-align: left;
			font-size: 12px;
			line-height: 1em;
			transition: 0.5s;

			&:last-child {
				margin: 0; }

			&:hover {
				background: #777777; }

			&.active {
				background: dodgerblue; }

			&.inactive {
				opacity: 0.3;
				cursor: default;
				pointer-events: none; } }

		button {
			width: 100%; } } }

.panel {
	@include panel; }






