.wrapper {
	position: fixed;
	z-index: 1000;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	pointer-events: none;

	.toasts {
		position: absolute;
		top: 70px;
		left: calc(50% - 250px);
		width: 500px; } }
