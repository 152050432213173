.modal {
	width: 500px;

	&.withActions {
		padding: 0 0 80px; }

	.heading {
		padding: 30px 30px 0;
		font-size: 2em;
		font-weight: bold; }

	.body {
		padding: 30px; }

	.actions {
		display: flex;
		position: absolute;
		bottom: 0;
		left: 0;
		padding: 10px;
		width: calc(100% - 20px);

		button {
			flex: 1;
			margin-right: 10px;
			padding: 0 20px;
			background: black;
			border: none;
			color: white;
			line-height: 50px;
			appearance: none;
			cursor: pointer;

			&:last-child {
				margin: 0; } } } }
