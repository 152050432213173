.header {
	position: fixed;
	z-index: 100;
	top: 0;
	left: 0;
	width: 100%;
	height: 60px;
	background: #222;
	//box-shadow: 0 0 20px rgba(0,0,0,0.5)
	color: white;

	a.logo {
		display: block;
		float: left;
		margin-right: 30px;
		padding: 0 30px;
		color: white;
		line-height: 55px; }


	nav {
		float: left;

		a {
			display: block;
			float: left;
			padding: 0 20px;
			color: white;
			line-height: 55px;

			&:hover {
				background: #222; }

			&.locked {
				opacity: 0.2;
				pointer-events: none; } } }



	.account {
		position: absolute;
		top: 20px;
		right: 100px;
		font-size: 12px; }


	button {
		position: absolute;
		top: 17px;
		right: 20px;
		padding: 0 10px;
		background: #333;
		border: solid 1px #555;
		color: #ccc;
		font-size: 12px;
		line-height: 20px;
		transition: 0.5s;
		cursor: pointer;

		&:hover {
			background: #434343; } } }
