.app {
	display: flex;
	margin: 0 0 15px;
	padding: 0 0 15px;
	border-bottom: dotted 1px #888;

	&:last-child {
		margin: 0;
		padding: 0;
		border: none; }

	.info {
		flex: 1;

		.id {
			color: #888;
			font-size: 0.9em; } }

	.cover {
		flex: 2; }

	.actions {
		margin: 0 0 30px;
		width: 120px;

		a {
			display: block;
			margin: 0 0 8px;
			padding: 0 15px;
			background: #518ee7;
			color: white;
			font-size: 11px;
			line-height: 30px;
			border-radius: 15px;

			&.disabled {
				opacity: 0.3;
				pointer-events: none; } } } }
