html, body {
	margin: 0; }

body {
	font-size: 0.9em;
	font-family: Helvetica, sans-serif; }

main {
	padding: 80px 20px 20px; }

section {
	margin: 20px 0; }

a {
	text-decoration: none; }

input, textarea {
	font-family: Helvetica, sans-serif; }


.header {
	position: relative;
	margin: 0 0 20px;

	button {
		float: right; } }

.help {
	margin: 0 0 20px;
	padding: 20px;
	background: #fafafa;
	border: solid 1px #888;
	border-radius: 5px;
	color: #555; }
