.wrapper {
	position: fixed;
	z-index: 900;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	pointer-events: none;

	.bg {
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		background: black;
		opacity: 0;
		transition: 0.7s; }

	.modal {
		position: absolute;
		top: 50%;
		left: 50%;
		min-width: 300px;
		min-height: 150px;
		background: white;
		box-shadow: 0 5px 15px rgba(0,0,0,0.5);
		opacity: 0;
		transform: translate(-50%, -50%);
		transition: 0.7s; }

	&.active {
		pointer-events: all;

		.bg {
			opacity: 0.8; }

		.modal {
			opacity: 1; } } }
